<template>
    <v-container fluid class="mt-8 mb-8">
        <v-row v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`">
            <v-col
                class="col-6"
                xs="12" 
                sm="5" 
                md="3"
                v-for="item in items" :key="item.name"
            >
                <v-skeleton-loader
                class="mx-auto"
                type="card, card-text"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="col-6" sm="4" md="4" lg="3" v-for="(menu_item, i) in menus" :key="i">
                <card-menu :menu_item="menu_item"></card-menu>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
import {menu} from "@/backend-api/menu/index" 
import {backendApi} from "@/backend-api/backend-api-sr"
import {env_conf} from "@/backend-api/env_conf/index"
import {appl_constant} from "@/backend-api/appl_constant/index"
import CardMenu from "@/components/CardMenu.vue"
  
export default {
    components: {
        CardMenu
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            menus: [],
            items: [{name: 'a'}, {name: 'b'}, {name: 'c'}, {name: 'd'}],
            theme: {
                isDark: false
            },
        }
    },
    computed: {
        cardHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '250'
                case 'sm': return '260'
                case 'md': return '350'
                case 'lg': return '350'
                case 'xl': return '350'
            }
        },
    },
    async mounted(){
        this.loading = true
        await this.getPullData()
        await this.getEnvVarConf()
        await this.getApplConstant()
        this.loading = false
    },
    methods: {
        async getPullData(){
            var respData = await menu.index(`?entity_id=GKI&appl_id=${this.$route.name}`, null, false, false, false)

            if (respData.status === 200) {
                this.menus = respData.data.menus
            }
        },
        async getEnvVarConf(){
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=GKI&appl_id=${this.$route.name}`, null, false, false, false)
            if (respData.status === 200) {
                this.$store.commit('SET_GROUP', respData.data.data)
                // console.log(this.$store.state.user.group);
            }

            const respDataBidang = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=BIDANG&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respDataBidang.status === 200) {
                if (respDataBidang.data.data) {
                    this.$store.commit('SET_BIDANG', respDataBidang.data.data)
                    this.bidang = this.$store.state.user.bidang
                    if (this.bidang) {
                    this.disabled_bidang = true
                    } else{
                    this.disabled_bidang = false
                    }
                }            
            }

            const respDataKomisi = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=KOMISI&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respDataKomisi.status === 200) {
                if (respDataKomisi.data.data) {
                    this.$store.commit('SET_KOMISI', respDataKomisi.data.data)
                    this.komisi = this.$store.state.user.komisi
                    if (this.komisi) {
                        this.disabled_komisi = true
                    } else{
                        this.disabled_komisi = false
                    }
                }
            }

            const respDataFlagThree = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=GLVISIBLE&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respDataFlagThree.status === 200) {
                if (respDataFlagThree.data.data) {
                    this.$store.commit('SET_FLAG_THREE', respDataFlagThree.data.data)
                    this.flag_three = this.$store.state.user.flag_three
                    if (this.flag_three) {
                        this.disabled_flag_three = true
                    } else{
                        this.disabled_flag_three = false
                    }
                }
            }

            const respDataIDPDT = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=IDPENDETA&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respDataIDPDT.status === 200) {
                if (respDataIDPDT.data.data) {
                    this.$store.commit('SET_ID_PDT', respDataIDPDT.data.data)
                    this.id_pdt = this.$store.state.user.id_pdt
                    if (this.id_pdt) {
                        this.disabled_id_pdt = true
                    } else{
                        this.disabled_id_pdt = false
                    }
                }
            }

            // TODO tambahkan get pendeta setelah jelas mau gimana
        },
        async getApplConstant(){
            var respDataBidang = await appl_constant.fetchApplConstant("?key_code=BIDANG", null, false, false, false)
            if (respDataBidang.status === 200) {
                this.bidangs = respDataBidang.data.data
            }

            var respDataKomisi = await appl_constant.fetchApplConstant("?key_code=KOMISI", null, false, false, false)
            if (respDataKomisi.status === 200) {
                this.komisies = respDataKomisi.data.data
            }
        },
    }
}
</script>
  
<style>
.ledger{
    background-color: #ea5044; 
    color: #ea5044;
}
.budget{
    background-color: #2eb3ec; 
    color: #2eb3ec;
}
.cash_bank{
    background-color: #f5aa3b; 
    color: #f5aa3b;
}
.target{
    background-color: #5581ad; 
    color: #5581ad;
}
.dashboard{
    background-color: #C3D046; 
    color: #C3D046;
}
</style>
