<template>
    <!-- <v-hover v-slot="{ hover }"> -->
        <v-card
            class="mx-auto"
            :color="menu_item.color"
            max-width="450"
            :height="cardHeight"
            tile
            :to="menu_item.menu_header_path"
        >
            <v-expand-transition mode="in-out">
                <v-container fluid class="fill-height">
                    <v-row align="center" justify="center">
                        <v-col class="col-6" sm="4" md="4" >
                            <div v-html="menu_item.icon"></div>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <h6 class="text-center text-white mt-2" >{{ menu_item.menu_header_caption }}</h6>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
            <!-- <v-expand-transition mode="in-out">
                <div
                    v-if="hover"
                    class="v-card--reveal white--text"
                    :class="menu_item.class"
                    style="height: 100%; opacity: 1;"
                >
                    <v-container fluid class="p-5">
                        <v-row>
                            <v-col cols="12">
                                <h6>{{ menu_item.menu_header_caption }}</h6>
                                <p class="mt-4">
                                    {{menu_item.menu_header_caption}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-expand-transition> -->
        </v-card>
    <!-- </v-hover> -->
</template>

<script>
import { mapState } from 'vuex'
import {menu} from "@/backend-api/menu/index" 
  
export default {
    data() {
        return {
            user: ''
        }
    },
    props:['menu_item'],
    computed: {
        cardHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '250'
                case 'sm': return '260'
                case 'md': return '350'
                case 'lg': return '350'
                case 'xl': return '350'
            }
        },
    },
    async mounted(){
        console.log(this.menu_item);
    },
    methods: {
    }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}
.kelassatuin{
    background-color: #ea5044;
    color: #ea5044;
}
.kelasduain{
    background-color: #2eb3ec;
    color: #2eb3ec;
}
.kelastigain{
    background-color: #ee69a7;
    color: #ee69a7;
}
.kelasempatin{
    background-color: #f5aa3b;
    color: #f5aa3b;
}
.kelaslimain{
    background-color: #4bb4c6;
    color: #4bb4c6;
}
.kelasenamin{
    background-color: #54b857;
    color: #54b857;
}
.kelastujuhin{
    background-color: #5581ad;
    color: #5581ad;
}
.master_budget{
    background-color: #ea5044;
    color: #ea5044;
}

</style>